/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

const ImageTextSummary = ({ image, title, description }) => (
  <div
    sx={{
      px: ["2.4rem", null, "3rem"],
      //pt: ["4rem", null, "5rem"],
      pb: "3rem",
    }}
  >
    <div
      sx={{
        height: ["14.7rem", null, "17rem"],
        pt: ['3rem'],
        textAlign: 'center',
        "> svg, img": {
          maxHeight: ["8rem", null, "10rem"],
        },
      }}
    >
      {image}
    </div>
    <Themed.h2
      sx={{
        textAlign: ['left', "center"],
        textTransform: "uppercase",
        fontSize: ["1.8rem", '2.4rem', "3.2rem"],
        mb: "1rem",
      }}
    >
      {title}
    </Themed.h2>
    <div
      className="scaleText"
      sx={{
        "--min-font-size": [1.4, null, 1.6],
        "--max-font-size": [1.4, null, 2],
        lineHeight: 1.5,
      }}
    >
      {description}
    </div>
  </div>
)

export default ImageTextSummary
